import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {Guid} from "guid-typescript";

export const CarrierApi = {
    create(clientId: Guid, carrier: Carrier): Promise<Carrier> {
        return postRequest(getBaseUrl(clientId), carrier);
    },
    getAuthSettings(clientId: Guid): Promise<CarrierAuthSettings> {
        return getRequest(`${getBaseUrl(clientId)}/auth`);
    },
    find(clientId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<Carrier>> {
        return getRequest(getBaseUrl(clientId), queryRequest);
    },
    findById(clientId: Guid, id: Guid): Promise<Carrier> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    update(clientId: Guid, id: Guid, carrier: Carrier): Promise<Carrier> {
        return putRequest(`${getBaseUrl(clientId)}/${id}`, carrier);
    },
    auth(clientId: Guid, id: Guid, data: any): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${id}/auth`, data);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/carriers`;
}

export interface Carrier {
    clientId: Guid;
    id: Guid;
    name: string;
    type: CarrierType;
    sandbox: boolean;
    secret: string;
    reference: string;
    carrierName?: string;
    tradingName?: string;
    businessAccount: boolean;
}

export function emptyCarrier(): Carrier {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        name: "",
        type: -1,
        sandbox: false,
        secret: "",
        reference: "",
        businessAccount: false
    };
}

export enum CarrierType {
    Parcel2Go = 1,
    ClickAndDrop = 2,
    AddressLabelOnly = 3,
    Unused = 4,
    Unused2 = 5,
    TransglobalExpress = 6,
    Custom = 7
}

export interface CarrierAuthSettings {
    p2g: P2GSettings;
}

export interface P2GSettings {
    clientId: string;
    sandbox: boolean;
    scopes: string;
}

export function emptyCarrierAuthSettings(): CarrierAuthSettings {
    return {
        p2g: {
            clientId: "",
            sandbox: true,
            scopes: ""
        }
    };
}

