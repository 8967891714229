import {Guid} from "guid-typescript";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";

export const AddressApi = {
    create(clientId: Guid, address: Address): Promise<Address> {
        return postRequest(getBaseUrl(clientId), address);
    },
    find(clientId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<Address>> {
        return getRequest(getBaseUrl(clientId), queryRequest);
    },
    findById(clientId: Guid, id: Guid): Promise<Address> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    update(clientId: Guid, id: Guid, address: Address): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${id}`, address);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/addresses`;
}

export interface Address {
    clientId: Guid;
    id: Guid;
    systemName: string;
    type: AddressType;
    title: string;
    name: string;
    lineOne: string;
    lineTwo: string;
    lineThree: string;
    city: string;
    postcode: string;
    county: string;
    country: string;
    phone: string;
    note: string;
    defaultAddress: boolean;
}

export function emptyAddress(type: AddressType = AddressType.Delivery): Address {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        systemName: "",
        type: type,
        title: "",
        name: "",
        lineOne: "",
        lineTwo: "",
        lineThree: "",
        city: "",
        postcode: "",
        county: "",
        country: "United Kingdom of Great Britain and Northern Ireland",
        phone: "",
        note: "",
        defaultAddress: false
    };
}

export enum AddressType {
    Delivery = 0,
    Return = 1,
    Collection = 2
}

