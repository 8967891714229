import {Guid} from "guid-typescript";
import {getRequest, putRequest} from "@/api/HttpMethods";

export const ClientShippingInfoApi = {
    get(clientId: Guid): Promise<ClientShippingInfo> {
        return getRequest(baseUrl(clientId));
    },
    set(clientId: Guid, info: ClientShippingInfo): Promise<void> {
        return putRequest(baseUrl(clientId), info);
    }
};

function baseUrl(clientId: Guid) {
    return `/internal/api/v1/clients/${clientId}/shipping/info`;
}

export interface ClientShippingInfo {
    defaultCountryOfOrigin: string;
    vatNumber?: string;
    eoriNumber?: string;
    iossNumber?: string;
    defaultCommodityCode?: string;
    exportReason: ExportReason;
    vatStatus: VatStatus;
}

export enum ExportReason {
    Sale = 1,
    Gift = 2,
    Sample = 3,
    Repair = 4,
    Documents = 5,
}

export enum VatStatus {
    NotRegistered = 0,
    Registered = 1,
    Individual = 2
}

export function emptyClientShippingInfo(): ClientShippingInfo {
    return {
        defaultCountryOfOrigin: "",
        exportReason: ExportReason.Sale,
        vatStatus: VatStatus.NotRegistered
    };
}
