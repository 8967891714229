import {getRequest} from "@/api/HttpMethods";
import {titleCase} from "title-case";

export const CountryApi = {
    list(): Promise<string[]> {
        return getRequest("/api/countries");
    }
};

export function displayCountry(country: string) {
    let result = titleCase(country.toLowerCase());

    if (country.includes("United Kingdom")) result = "United Kingdom";
    return result;
}
