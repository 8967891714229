








import {Component, Model, Prop, Vue} from "vue-property-decorator";
import {CountryApi, displayCountry} from "@/api/CountryApi";

interface SelectOption {
    value: string | number | boolean | null;
    text: string;
}

@Component
export default class CountrySelect extends Vue {
    @Model("update:country", {type: String}) readonly country!: string | null;
    @Prop({type: Boolean, default: true}) readonly required!: boolean;

    countryOptions: SelectOption[] = [];

    get model(): string | null {
        return this.country ? displayCountry(this.country) : null;
    }

    set model(value: string | null) {
        this.$emit("update:country", value);
    }

    created() {
        if (!this.country) {
            this.countryOptions.push({
                value: null,
                text: this.$t("no-selection").toString()
            });
        }

        CountryApi.list()
            .then((data: any[]) => {
                data.map(c => ({
                    value: displayCountry(c.name),
                    text: displayCountry(c.name)
                }))
                .forEach(c => {
                    this.countryOptions.push(c);
                });
            });
    }
}
