

















import {Component, Model, Prop, Vue} from "vue-property-decorator";
import Icon from "@/components/helpers/Icon.vue";
import {formatISO} from "date-fns";

@Component({
    components: {Icon}
})
export default class DateInput extends Vue {
    @Model("update:value", {type: [String, Date], default: ""}) value!: string;
    @Prop({type: String, default: () => null}) readonly resetValue!: string | null;

    get date(): string {
        return this.value ? formatISO(new Date(this.value), {representation: "date"}) : "";
    }

    set date(value: string) {
        this.$emit("update:value", value);
    }
}
