import {Guid} from "guid-typescript";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";

export const OrderSourceApi = {
    create(clientId: Guid, orderSource: OrderSource): Promise<OrderSource> {
        return postRequest(getBaseUrl(clientId), orderSource);
    },
    getAuthSettings(clientId: Guid, type: OrderSourceType): Promise<OrderSourceAuthSettings> {
        return getRequest(`${getBaseUrl(clientId)}/auth?type=${type}`);
    },
    find(clientId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<OrderSource>> {
        return getRequest(getBaseUrl(clientId), queryRequest);
    },
    findById(clientId: Guid, id: Guid): Promise<OrderSource> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    update(clientId: Guid, id: Guid, orderSource: OrderSource): Promise<OrderSource> {
        return putRequest(`${getBaseUrl(clientId)}/${id}`, orderSource);
    },
    auth(clientId: Guid, id: Guid, data: any): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${id}/auth`, data);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/orders/sources`;
}

export interface OrderSource {
    clientId: Guid;
    id: Guid;
    name: string;
    type: OrderSourceType;
    secret: string;
    sandbox: boolean;
    siteId: AmazonMarketplace | OnBuySite;
    shopIdentifier: string;
    consumerKey: string;
    hmacChallenge: string;
}

export enum OrderSourceType {
    Manual = 0,
    Ebay = 1,
    Amazon = 2,
    Woo = 3,
    Etsy = 4,
    OnBuy = 5,
    Shopify = 6,
    Ekm = 7,
    Squarespace = 8,
    BigCommerce = 9,
    Custom = 10,
    Webflow = 11,
}

export function emptyOrderSource(): OrderSource {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        name: "",
        type: -1,
        secret: "",
        //eBay
        sandbox: false,
        //Amazon, OnBuy
        // @ts-ignore
        siteId: "",
        //Woo
        consumerKey: "",
        shopIdentifier: "",
        // Shopify
        hmacChallenge: ""
    };
}

export interface OrderSourceAuthSettings {
    ebay: EbaySettings;
    amazon: AmazonSettings;
    shopify: OAuthSettings;
    ekm: OAuthSettings;
    etsy: OAuthPkceSettings;
    squarespace: OAuthSettings;
    bigCommerce: OAuthSettings;
}

export interface AmazonSettings extends OAuthSettings {
    sandbox: boolean;
}

export interface EbaySettings {
    appId: string;
    ruName: string;
    sandbox: boolean;
    scopes: string;
}

export interface OAuthSettings {
    clientId: string;
    scopes: string;
}

export interface OAuthPkceSettings {
    clientId: string;
    scopes: string;
    codeChallenge: string;
}

export function emptyOrderSourceAuthSettings(): OrderSourceAuthSettings {
    return {
        ebay: {
            appId: "",
            ruName: "",
            sandbox: true,
            scopes: ""
        },
        amazon: {
            clientId: "",
            scopes: "",
            sandbox: true
        },
        shopify: {
            clientId: "",
            scopes: ""
        },
        ekm: {
            clientId: "",
            scopes: ""
        },
        squarespace: {
            clientId: "",
            scopes: ""
        },
        etsy: {
            clientId: "",
            scopes: "",
            codeChallenge: ""
        },
        bigCommerce: {
            clientId: "",
            scopes: ""
        }
    };
}

export enum AmazonMarketplace {
    //Europe
    UK = "A1F83G8C2ARO7P",
    France = "A13V1IB3VIYZZH",
    Germany = "A1PA6795UKMFR9",
    Italy = "APJ6JRA9NG5V4",
    Spain = "A1RKKUPIHCS9HS",
    Netherlands = "A1805IZSGTT6HS",
    Sweden = "A2NODRKZP88ZB9",
    Turkey = "A33AVAJ2PDY3EV",
    UAE = "A2VIGQ35RCS4UG",
    India = "A21TJRUUN4KGV",

    //North America
    US = "ATVPDKIKX0DER",
    Canada = "A2EUQ1WTGCTBG2",
    Mexico = "A1AM78C64UM0Y8",
    Brazil = "A2Q3Y263D00KWC",

    //Far East
    Singapore = "A19VAU5U5O7RUS",
    Australia = "A39IBJ37TRP1C6",
    Japan = "A1VC38T7YXB528"
}

export enum OnBuySite {
    UK = "2000",
    France = "2001",
    Germany = "2002",
}
