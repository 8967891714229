























































import {Vue, Component, Prop} from "vue-property-decorator";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";

@Component
export default class OrderSourceLogo extends Vue {
    @Prop({type: Number, required: true}) readonly type!: OrderSourceType;
    @Prop({type: String}) readonly title!: string | null;
    OrderSourceType: typeof OrderSourceType = OrderSourceType;
}
