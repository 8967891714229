import {Guid} from "guid-typescript";
import {getRequest} from "@/api/HttpMethods";

export const MetricsApi = {
    getOrdersUsage(clientId: Guid): Promise<number> {
        return getRequest(`${getBaseUrl(clientId)}/orders/usage`);
    },
    getNumberOfOrdersByStatus(clientId: Guid): Promise<OrderCountByStatus> {
        return getRequest(`${getBaseUrl(clientId)}/orders/count`);
    },
    getNumberOfOrdersToDispatch(clientId: Guid): Promise<number> {
        return getRequest(`${getBaseUrl(clientId)}/orders/count/todispatch`);
    },
    getProductsToPrepare(clientId: Guid): Promise<ActiveProduct[]> {
        return getRequest(`${getBaseUrl(clientId)}/products/toprepare`);
    },
    getOrderFilesUsage(clientId: Guid): Promise<number> {
        return getRequest(`${getBaseUrl(clientId)}/order-files/usage`);
    },
    getChart(clientId: Guid, startDate: string | null, endDate: string | null, chartDefinition: any): Promise<any> {
        const url = new URL(`${getBaseUrl(clientId)}/chart`, window.location.origin);

        if (startDate)
            url.searchParams.set("startDate", startDate);
        if (endDate)
            url.searchParams.set("endDate", endDate);

        for (const key in chartDefinition) {
            url.searchParams.set(key, chartDefinition[key]);
        }

        return getRequest(url.toString());
    },
};

export interface ActiveProduct {
    frequency: number;
    name: string;
    quantity: string;
}

export interface OrderCountByStatus {
    outstanding: number;
    processing: number;
    dispatched: number;
}

function getBaseUrl(clientId: Guid) {
    return `/internal/api/v1/clients/${clientId}/metrics`;
}
export enum ChartType {
    Bar,
    Line,
    Area,
    Pie,
    Donut
}
